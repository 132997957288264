import scroll from 'core/scroll'
// import resize from 'helpers/resize'
import Menu from '../menu/Menu'

export default class Header {
  constructor (el) {
    this.el = el
    this.menu = new Menu(el.parentNode.querySelector('.menu'))

    scroll.instance().on(this.scroll)
    this.button = this.el.querySelector('.header__toggle-menu')
    this.button.addEventListener('click', this.toggle)
  }

  barVisible = false

  toggle = () => {
    if (this.menu.opened) this.menu.hide()
    else this.menu.show()
    this.button.classList.toggle('opened', this.menu.opened)
  }

  scroll = () => {
    const { scrollTop } = scroll
    const needBarVisible = scrollTop() > 250

    if (needBarVisible !== this.barVisible) {
      document.body.classList.toggle('bar-visible', needBarVisible)
      this.barVisible = needBarVisible
    }
  }

  flush () {
    this.button.removeEventListener('click', this.toggle)
    this.button = null
    this.menu.flush()
    this.menu = null
    scroll.instance().off(this.scroll)
  }

  resize () {}
}
