import PageManager from 'navigation/SuperPageManager'

import Page from 'navigation/SuperPage'
import Project from './project/Project'

import scroll from 'core/scroll'

import resize from 'helpers/resize'

class Main {
  constructor (el) {
    this.el = el
    this.createVirtualScroll()
    this.createPageManager()

    resize.root = document.querySelector('.container')
    this.listenResize()
  }

  createPageManager () {
    const panels = this.el.querySelectorAll('.panel')

    this.pageManager = new PageManager(
      document.querySelector('.container'),
      '.page',
      {
        '*': Page,
        'work/*': Project
      },
      { panels }
    )
  }

  createVirtualScroll () {
    scroll.init()
  }

  listenResize () {
    resize.add(this)
    this.resize()
  }

  resize = () => {
    this.pageManager.resize()
  }
}

export default Main
