import reverse from 'lodash/reverse'
import anime from 'animejs'

export default class Menu {
  constructor (el) {
    this.el = el
    this.background = el.querySelector('.menu__background')
    this.links = Array.from(el.querySelectorAll('.menu__item'))
    this.opened = false
  }

  show (pageName) {
    this.opened = true

    const timeline = anime.timeline({
      duration: 600,
      delay: (el, i) => i * 50,
      easing: 'easeOutSine'
    })

    timeline.add(
      {
        targets: this.el,
        skew: [-20, 0],
        translateX: ['20vw', 0]
      },
      0
    )

    timeline.add(
      {
        translateX: ['100%', 0],
        targets: this.background
      },
      0
    )

    timeline.add(
      {
        targets: reverse(this.links.slice(0)),
        translateX: [100, 0],
        opacity: {
          value: [0, 1],
          easing: 'linear'
        }
      },
      100
    )

    this.el.style.display = 'block'
    return timeline.finished
  }

  hide () {
    this.opened = false

    const timeline = anime.timeline({
      duration: 400,
      easing: 'easeInSine',
      delay: (el, i) => i * 50
    })

    timeline.add(
      {
        skew: [0, 20],
        translateX: [0, '-20vw'],
        targets: this.el
      },
      200
    )

    timeline.add(
      {
        translateX: [0, '-100%'],
        targets: this.background
      },
      200
    )

    timeline.add(
      {
        translateX: [0, -100],
        opacity: {
          value: [1, 0],
          easing: 'linear'
        },
        targets: this.links
      },
      0
    )

    return timeline.finished.then(() => {
      this.el.style.display = 'none'
    })
  }

  flush () {}

  resize () {}
}
