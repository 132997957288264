import browser from 'helpers/browser'
import math from 'helpers/math'
import bowser from 'bowser'

import Inrtia from 'inrtia'
import router from 'core/router'
import scroll from 'core/scroll'
import raf from '@internet/raf'

import now from 'lodash/now'
import reduce from 'lodash/reduce'
import sortBy from 'lodash/sortBy'
import toPairs from 'lodash/toPairs'
import { createStore } from '@internet/state'
import anime from 'animejs'

const SPEED = 1.2

class Carousel {
  constructor (container, items) {
    this.el = container
    this.items = items
    this.step = createStore({ step: 0 }).step
    this.total = items.length

    this.inrtia = new Inrtia({
      value: this.minStep,
      precision: 0.1,
      perfectStop: true,
      friction: bowser.desktop ? 10 : 5
    })
    this.resize()

    this.selectStep(0)
    this.updatePosition(true)
    this.toggleEvents(true)
  }

  toggleEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.el[method](bowser.desktop ? 'mousedown' : 'touchstart', this.mousedown)
    window[method](bowser.desktop ? 'mouseup' : 'touchend', this.mouseup)
    window[method](bowser.desktop ? 'mousemove' : 'touchmove', this.mousemove, { passive: false })
    raf[add ? 'add' : 'remove'](this.updatePosition)
  }

  enable () {
    this._enable = true
  }

  disable () {
    this._enable = false
  }

  prev = () => {
    const step = math.clamp(this.step.current - this.offset, 0, this.end)
    // this.inrtia.to(this.steps[step])
    this.animateTo(step)
  }

  next = () => {
    const step = math.clamp(this.step.current + this.offset, 0, this.end)
    // this.inrtia.to(this.steps[step])
    this.animateTo(step)
  }

  goTo = (rawStep, force = false) => {
    if (rawStep === this.step.current && force === false) return
    const step = this.steps[+rawStep]
    this.selectStep(+rawStep)
    this.inrtia.stop()
    this.inrtia.value = step
    this.inrtia.targetValue = step
    this.updatePosition(true)
  }

  animateTo = (rawStep) => {
    if (rawStep === this.step.current) return
    const step = this.steps[rawStep]

    this.inrtia.stop()
    anime.remove(this.inrtia)
    this.selectStep(rawStep)

    const value = this.inrtia.value

    anime({
      targets: this.inrtia,
      value: [value, step],
      targetValue: [value, step],
      easing: 'easeOutSine',
      duration: 600,
      complete: () => this.updatePosition(true),
      change: () => this.updatePosition(true)
    })
  }

  mousedown = (event) => {
    if (this._enable === false) return

    event = browser.mouseEvent(event)
    this.inrtia.stop()

    this.firstFrame = {
      time: now(),
      x: event.pageX,
      y: (event.pageY - scroll.scrollTop()),
      value: this.inrtia.value,
      prevent: false
    }

    this.el.classList.add('grabbing')

    this.inrtia.stop()
  }

  hasClicked (event) {
    const distanceX = event.pageX - this.firstFrame.x
    const distanceY = (event.pageY - scroll.scrollTop()) - this.firstFrame.y
    const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY)
    const delay = now() - this.firstFrame.time
    return delay < 400 && Math.abs(distance) < 30
  }

  hasScrolled (event) {
    const distanceX = event.pageX - this.firstFrame.x
    const distanceY = (event.pageY - scroll.scrollTop()) - this.firstFrame.y
    const angle = Math.atan2(distanceY, distanceX) * (180 / Math.PI)

    const range = 30
    const gap = Math.abs(90 - Math.abs(angle))
    const horizontal = gap >= 90 - range

    // const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY)
    return horizontal
  }

  mouseup = (event) => {
    if (this._enable === false || !this.firstFrame) return

    event = browser.mouseEvent(event)

    if (this.hasClicked(event)) this.click(event.target)

    const distance = event.pageX - this.firstFrame.x
    const value = this.firstFrame.value + (distance * SPEED)
    const clampedValue = Math.min(this.minStep, Math.max(this.maxStep, value))
    const newValue = this.findStep(clampedValue, true)

    this.inrtia.to(newValue)
    this.firstFrame = false
    this.el.classList.remove('grabbing')
  }

  click (target) {
    const link = target.querySelector('a[data-navigo]')
    if (!link) return
    const href = router.getLinkPath(link)
    router.navigate(href)
  }

  reset () {
    if (!this.inrtia) return
    const newValue = this.steps[this.step.current]
    if (newValue === this.inrtia.targetValue) return
    this.inrtia.to(newValue)
  }

  mousemove = (event) => {
    if (this._enable === false || !this.firstFrame) return

    const mouseEvent = browser.mouseEvent(event)
    const distance = mouseEvent.pageX - this.firstFrame.x

    if (this.hasScrolled(mouseEvent)) event.preventDefault()
    else return

    if (this.firstFrame.prevent) event.preventDefault()

    const value = this.firstFrame.value + (distance * SPEED)
    const clampedValue = Math.min(this.minStep, Math.max(this.maxStep, value))

    this.inrtia.to(clampedValue)
  }

  findStep (value, select = false) {
    const sorted = sortBy(toPairs(this.steps), (step, i) => Math.abs(value - step[1]))
    const index = parseInt(sorted[0][0])

    if (select) this.selectStep(index)
    return this.steps[index]
  }

  updatePosition = (force) => {
    if (this._enable === false) return
    if (this.inrtia.stopped && force !== true) return
    const value = this.inrtia.update()
    this.el.style.transform = `translate3d(${value}px, 0, 0)`
    // this.el.style.transform = `translateX(${value}px)`
  }

  selectStep (step) {
    this.step.set(step)
  }

  resize () {
    // const outer = this.el.parentNode
    // const outerWidth = outer.offsetWidth
    // const innerWidth = this.el.offsetWidth
    // const outerPadding = this.el.offsetLeft
    // const innerPadding = this.items[0].offsetLeft

    // let max = 0

    // if (outerWidth - (innerPadding * 2) < innerWidth) {
    //   const difference = (innerWidth + innerPadding) - (outerWidth - (outerPadding * 2))
    //   max = -Math.max(0, difference)
    // }

    this.end = this.items.length

    this.steps = reduce(this.items, (memo, li, i) => {
      let w = 0
      if (i !== 0) {
        const s = window.getComputedStyle(li)
        w = -(li.offsetWidth + parseInt(s.marginLeft) + parseInt(s.marginRight))
        w += memo[i - 1]
      }

      // if (w <= max) this.end = Math.min((i + 1), this.end)
      // memo.push(Math.max(max, w))
      memo.push(w)
      return memo
    }, [])

    this.minStep = this.steps[0]
    this.maxStep = this.steps[this.steps.length - 1]
    this.stepWidth = Math.abs(this.steps[1] - this.steps[0])
    this.offset = Math.max(1, (this.items.length - this.end) + 1)

    const lastAttribute = this.el.getAttribute('style', '')
    if (this._enable === false) this.el.setAttribute('style', '')
    else if (lastAttribute === '') this.reset()

    this.updatePosition(true)
  }

  flush () {
    this.toggleEvents(false)
    this.step.unlistenAll()
  }
}

export default Carousel
