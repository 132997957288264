import SuperPage from 'navigation/SuperPage'
import Carousel from 'modules/carousel/Carousel'
import router from 'core/router'
import each from 'lodash/each'
import anime from 'animejs'

class Project extends SuperPage {
  constructor (el) {
    super(...arguments)
    this.isPhotos = el.classList.contains('photos')
    this.isVideos = el.classList.contains('videos')
    this.backBtn = el.querySelector('.project__back')

    if (this.isPhotos) this.preparePhotos(el)
    if (this.isVideos) this.prepareVideo(el)

    this.toggleEvents()
  }

  preparePhotos (el) {
    this.list = el.querySelector('.photos__list')
    this.photos = el.querySelectorAll('.photos__item')
    this.next = this.el.querySelector('.photos__next')
    this.prev = this.el.querySelector('.photos__prev')
    this.count = this.el.querySelector('.photos__count')
    this.gallery = this.el.querySelector('.photos__gallery')

    this.carousel = new Carousel(
      el.querySelector('.photos__gallery-inner'),
      el.querySelectorAll('.photos__slide')
    )
  }

  prepareVideo (el) {
  }

  toggleEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.backBtn[method]('click', this.back)

    if (this.isPhotos) {
      this.prev[method]('click', this.carousel.prev)
      this.next[method]('click', this.carousel.next)
      this.carousel.step[add ? 'listen' : 'unlisten'](this.onStepUpdate)
      each(this.photos, slide => slide[method]('click', this.goToSlide))
    }
  }

  onStepUpdate = () => {
    const current = this.carousel.step.current + 1
    this.prev.classList.toggle('disable', current <= 1)
    this.next.classList.toggle('disable', current >= this.carousel.end)
    this.count.innerText = current
  }

  goToSlide = (event) => {
    if (!this.carouselOpened) this.toggleCarousel(true)
    const target = event.currentTarget
    this.carousel.goTo(target.getAttribute('data-id'))
  }

  toggleCarousel (toCarousel = true) {
    const from = toCarousel ? this.list : this.gallery
    const to = !toCarousel ? this.list : this.gallery
    this.carouselOpened = toCarousel
    const offset = 250

    const timeline = anime.timeline({
      duration: 400
    })

    timeline.add({
      targets: from,
      easing: 'easeInSine',
      translateY: [0, -offset],
      opacity: {
        value: [1, 0],
        easing: 'linear'
      }
    })

    timeline.add({
      targets: to,
      easing: 'easeOutSine',
      translateY: [offset, 0],
      begin: () => {
        from.style.display = 'none'
        to.style.display = 'block'
        if (toCarousel) {
          this.carousel.resize()
          this.carousel.goTo(this.carousel.step.current, true)
        }
      },
      opacity: {
        value: [0, 1],
        easing: 'linear'
      }
    })

    return timeline.finished
  }

  back = event => {
    event.preventDefault()

    if (this.carouselOpened) return this.toggleCarousel(false)
    const target = event.currentTarget
    const next = router.lastPage() || target.getAttribute('href')
    router.navigate(next)
  }

  resize () {
    this.carousel && this.carousel.resize()
    super.resize()
  }

  flush () {
    super.flush()
    this.toggleEvents(false)
    this.carousel && this.carousel.flush()
    this.carousel = null
  }
}

Project.pageName = 'Project'

export default Project
